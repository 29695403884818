export const InitialState = {
  updateAckButton: false,
  hamburgerMenu: false,
  isMobile: false,
  isModalOpen: false,
  isLoadingData: true,
  showHcpDisclaimer: false,
  error: {
    origin: null,
    message: null,
  },
};

export const actionTypes = {
  SET_HAMBURGER_MENU: "SET_HAMBURGER_MENU",
  SET_IS_MOBILE: "SET_IS_MOBILE",
  SET_IS_MODAL_OPEN: "SET_IS_MODAL_OPEN",
  SET_ERROR: "SET_ERROR",
  SET_SHOW_HCP_DISCLAIMER: "SET_SHOW_HCP_DISCLAIMER",
  SET_IS_LOADING_DATA: "SET_IS_LOADING_DATA",
  SET_ACK_BUTTON: "SET_ACK_BUTTON",
};

export const Reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_ACK_BUTTON:
      return {
        ...state,
        updateAckButton: action.payload,
      };
    case actionTypes.SET_IS_LOADING_DATA:
      return {
        ...state,
        isLoadingData: action.payload,
      };
    case actionTypes.SET_HAMBURGER_MENU:
      return {
        ...state,
        hamburgerMenu: action.payload,
      };
    case actionTypes.SET_IS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    case actionTypes.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case actionTypes.SET_SHOW_HCP_DISCLAIMER:
      return {
        ...state,
        showHcpDisclaimer: action.payload,
      };
    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: {
          origin: action.payload.origin,
          message: action.payload.message,
        },
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
